<template>
  <div class="protocol">
      <portlet title="ئىشلىتىش كېلىشىمنامىسى">
        <template v-slot:body>
          <protocol-content />
        </template>
      </portlet>
  </div>
</template>

<script>
  import Portlet from "../../../partials/content/Portlet";
  import ProtocolContent from "../../../components/protocol/ProtocolContent";
  import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs.module";
  export default {
    name: "index",
    components: { Portlet, ProtocolContent },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئىشلىتىش كېلىشىمنامىسى", route: "/protocol" },
        { title: "كېلىشىمنامە مەزمۇنى" }
      ]);
    },
    data(){
      return {}
    }
  }
</script>

<style scoped>

</style>
